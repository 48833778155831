@import '~theme/variables';

$animation-time: 0.3s;

.scrollableArea {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.row {
  display: flex;
}

.carousel {
  position: relative;
}

.content {
  position: relative;
  height: 100%;

  .titleWrapper {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.2s ease;
    height: 100%;
    display: flex;
    gap: $space-l;
    padding-right: 12rem;
    z-index: 1;
  }

  &.hidden .titleWrapper {
    z-index: -1;
    transform: scale(0.95) translateX(20rem);
    opacity: 0;
  }
}

.scrollContent {
  display: flex;
  transition: transform 0.4s ease;
  gap: var(--gap);
}

.slide {
  width: var(--slide-width);
  height: 100%;
  flex-shrink: 0;
}

.contentSlide {
  position: absolute;
}

.description {
  line-height: 2.8rem;
  margin: 0;
}

.button {
  max-width: fit-content;
}

.arrowButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 4rem;

  div {
    display: flex;
  }
}
