@import '~theme/variables';

.wrapper {
  position: relative;
  border-radius: $border-radius-05;
  overflow: hidden;
  display: block;
  word-break: break-word;
  aspect-ratio: 4/6.4;

  .cardContent {
    color: transparent;

    & .description p {
      color: transparent;
      transition: color $transition-01;
    }
  }

  @include breakpoint(md) {
    &:focus-within,
    &:hover {
      & .backgroundImage {
        transform: scale(110%);
      }

      & .container::after {
        opacity: 1;
      }

      & .cardContent {
        & .description p {
          color: $color-neutral-06;
        }

        & .button {
          color: $color-neutral-06;
          border-color: $color-neutral-06;
          &:hover {
            background-color: white;
            color: $color-neutral-01;
          }
        }
      }
    }
  }
}

.active {
  @include breakpoint(lg, max) {
    & .backgroundImage {
      transform: scale(110%);
    }

    & .container::after {
      opacity: 1;
    }

    & .cardContent {
      & .description p {
        color: $color-neutral-06;
      }

      & .button {
        color: $color-neutral-06;
        border-color: $color-neutral-06;
      }
    }
  }
}

.backgroundImage {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.container {
  position: relative;
  white-space: normal;
  z-index: $z-index-above;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-05;
  background: linear-gradient(
    180deg,
    rgba(16, 15, 13, 0.48) 0%,
    rgba(16, 15, 13, 0) 31.03%
  );
  padding: $space-l;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & p {
    font-size: 1.8rem;

    @include breakpoint(lg) {
      font-size: 2rem;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity $transition-01;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: $z-index-background;
    background: linear-gradient(
        180deg,
        rgba(16, 15, 13, 0.48) 0%,
        rgba(16, 15, 13, 0) 31.03%
      ),
      linear-gradient(0deg, rgba(16, 15, 13, 0.8), rgba(16, 15, 13, 0.8));
  }
}

.title {
  font-size: 2.6rem;
  @include breakpoint(md) {
    font-size: 4rem;
    line-height: 5rem;
  }
}

.description {
  font-size: 2rem;
  // line-height: 3.2rem;
  margin-bottom: $space-l;
}

.button {
  color: transparent;
  border: 0.2rem solid transparent;
  transition: color $transition-01, border-color $transition-01,
    background-color $transition-01;
}
